import TextField, { TextFieldProps } from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import React from "react";

interface FormInputTextProps {
  name: string;
  control: any;
  register: any;
  muiProps: TextFieldProps;
}

const FormInputText = ({
  name,
  control,
  register,
  muiProps,
}: FormInputTextProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          {...register(name)}
          onChange={onChange}
          value={value}
          {...muiProps}
        />
      )}
    />
  );
};

export default FormInputText;
