import './App.css'
import { useState, useRef, useEffect, reset } from 'react'
import StarsRating from 'stars-rating'
import { Button, Modal, Box, Alert } from '@mui/material'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import FormInputText from './FormInputTextField.tsx'
import { Tooltip } from 'react-tooltip'
import timings from './Timings.svg'
import dlogo from './Direction.svg'

function App() {
  const [open, setOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [service1, setService1] = useState(false)
  const [service2, setService2] = useState(false)
  const [service3, setService3] = useState(false)
  const [service4, setService4] = useState(false)
  const [service5, setService5] = useState(false)
  const [service6, setService6] = useState(false)
  const [service7, setService7] = useState(false)
  const [service8, setService8] = useState(false)
  const [g1Modal, setG1Modal] = useState(false)
  const [g2Modal, setG2Modal] = useState(false)
  const [g3Modal, setG3Modal] = useState(false)
  const [g4Modal, setG4Modal] = useState(false)
  const [g5Modal, setG5Modal] = useState(false)
  const [g6Modal, setG6Modal] = useState(false)

  console.log(open, service1)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  const contactUsFormSchema = Yup.object().shape({
    name: Yup.string().trim().label('Name').min(3).max(30).required(),
    phoneNumber: Yup.number()
      .label('Phone number')
      .positive()
      .min(1)
      .max(9999999999)
      .required(),
    email: Yup.string().label('Email').email().required(),
    message: Yup.string().trim().label('Message').min(3).max(3000).required(),
  })
  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(contactUsFormSchema),
    mode: 'onTouched',
    defaultValues: { name: '', phoneNumber: '', message: '' },
  })

  const formBody = []
  const [saveStatus, setSaveStatus] = useState(false)
  const [submissionError, setSubmissionError] = useState('')

  const onSubmit = (data) => {
    for (const property in data) {
      const encodedKey = encodeURIComponent(property)
      const encodedValue = encodeURIComponent(data[property])
      formBody.push(encodedKey + '=' + encodedValue)
    }
    const finalBody = formBody.join('&')
    fetch('http://localhost:3001/contact/firstChoiceEmail', {
      // Add your endpoint here to send email
      //fetch(, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: finalBody,
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((json) => {
            throw new Error(json.message)
          })
        } else {
          return res.json()
        }
      })
      .then((res) => {
        console.log('response ', res)
        if (res.message === 'success') {
          setSaveStatus(true)
        }
      })
      .catch((err) => {
        console.log('Error ', err)
        setSubmissionError('Error: please email info@1stchoicefingerprint.ca')
      })
  }

  useEffect(() => {
    if (saveStatus) {
      setTimeout(() => {
        setSaveStatus(false)
      }, 2000)
      reset()
    }
  }, [saveStatus, setSaveStatus])

  useEffect(() => {
    if (submissionError) {
      setTimeout(() => {
        setSubmissionError('')
      }, 5000)
      reset()
    }
  }, [submissionError, setSubmissionError])

  return (
    <div
      className="App"
      onClick={() =>
        (g1Modal && setG1Modal(false)) ||
        (g2Modal && setG2Modal(false)) ||
        (g3Modal && setG3Modal(false)) ||
        (g4Modal && setG4Modal(false)) ||
        (g5Modal && setG5Modal(false)) ||
        (g6Modal && setG6Modal(false))
      }
    >
      <div className="App-header">
        <nav className="bg-white border-gray-200 w-full px-2 sm:px-5 py-1.5 rounded sticky top-0 z-50">
          <div className="sm:flex flex-col md:flex md:flex-row md:justify-between mx-auto md:pl-10 md:pr-7">
            {/* Small heading */}
            <div className="inline-block md:hidden">
              <a href="#home" className="float-left">
                <img
                  src={require('./logo.png')}
                  className="h-9 mr-3 sm:h-9"
                  alt="1 Choice Fingerprint Services Ltd. Logo"
                />
              </a>
              <button
                onClick={() => setOpen(!open)}
                data-collapse-toggle="navbar-cta"
                type="button"
                className="float-right inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="navbar-cta"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            {/* Big Heading */}
            <a href="#home" class="hidden md:flex items-center">
              <img
                src={require('./logo.png')}
                className="h-9 mr-3 sm:h-9"
                alt="1 Choice Fingerprint Services Ltd. Logo"
              />
            </a>
            <div className="hidden md:flex md:order-2">
              <button
                type="button"
                className="text-white hidden md:block bg-[#002654] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-2xl px-5 py-2.5 text-center mr-3 md:mr-0 bg-[#002654] dark:hover:bg-blue-500 dark:focus:ring-blue-100 md:visible"
                onClick={() => setModalOpen(true)}
              >
                Contact us
              </button>
              <button
                onClick={() => setOpen(!open)}
                data-collapse-toggle="navbar-cta"
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="navbar-cta"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div
              className={`items-center justify-between ${open ? '' : 'hidden'} md:block md:bg-white sm:p-4 sm:pb-7 sm:pt-2 sm:bg-[#042e6d] sm:mt-2 sm:rounded-2xl md:p-0`}
            >
              <div
                className={`items-center justify-between ${open ? '' : 'hidden'} w-full md:flex md:w-auto md:order-1`}
                id="navbar-cta"
              >
                <ul className="flex flex-col sm:p-10 md:p-1 mt-1  sm:shadow-2xl md:shadow-none border-gray-100 rounded-3xl md:flex-row md:space-x-8 md:mt-0 md:font-medium md:border-0 md:text-3xl sm:bg-white">
                  <li
                    className="sm:my-3 md:my-0"
                    onClick={() => setOpen(false)}
                  >
                    <a
                      href="#home"
                      className="block py-2 pl-3 pr-4 text-white bg-[#002654] rounded md:bg-transparent md:text-blue-800 md:p-0 dark:text-white md:text-2xl"
                      aria-current="page"
                    >
                      Home
                    </a>
                  </li>
                  <li
                    className="sm:my-4 md:my-0"
                    onClick={() => setOpen(false)}
                  >
                    <a
                      href="#services"
                      className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue dark:text-gray-500 sm:dark:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                    >
                      Services
                    </a>
                  </li>
                  <li
                    className="sm:my-4 md:my-0"
                    onClick={() => setOpen(false)}
                  >
                    <a
                      href="#about-us"
                      className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue dark:text-gray-500 dark:hover:bg-gray-700 sm:dark:text-gray-900 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                    >
                      About Us
                    </a>
                  </li>
                  <li
                    className="sm:my-4 md:my-0"
                    onClick={() => setOpen(false)}
                  >
                    <a
                      href="#whyUs"
                      className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue dark:text-gray-500 dark:hover:bg-gray-700 sm:dark:text-gray-900 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                    >
                      Why Us
                    </a>
                  </li>
                  <li
                    className="sm:my-4 md:my-0"
                    onClick={() => setOpen(false)}
                  >
                    <a
                      href="#reviews"
                      className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue dark:text-gray-500 dark:hover:bg-gray-700 sm:dark:text-gray-900 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                    >
                      Testimonials
                    </a>
                  </li>
                  <li className="sm:my-4 md:my-0">&nbsp;</li>
                  <li className="sm:my-4 md:my-0">&nbsp;</li>

                  <li
                    className="sm:my-0 md:my-0 block"
                    onClick={() => setOpen(false)}
                  >
                    <p class="text-base leading-relaxed text-[#00348D] sm:my-0">
                      <img
                        className="sm:px-0"
                        class="w-10 h-10"
                        src={timings}
                        align="left"
                        alt="1 Choice Fingerprint Services Ltd. Logo"
                      />
                      <b>Monday - Saturday</b>
                      <h1 className="md:text-1xl font-extrabold text-[#cd1a3b] ">
                        <b>09 AM - 05 PM</b>
                      </h1>
                    </p>
                  </li>
                  <li
                    className="sm:my-4 md:my-0 md:hidden"
                    onClick={() => setOpen(false)}
                  >
                    <button
                      className="sm:block md:hidden text-sm rounded-3xl sm:text-[0.8em] bg-green-700 p-1 text-center w-1/2 mx-auto mt-9 mb-4"
                      onClick={() => setModalOpen(true)}
                    >
                      Contact Us
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        {/* contact us form modal */}
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={style}>
              {saveStatus && (
                <Alert severity="success">Information saved!</Alert>
              )}
              {!saveStatus && submissionError && (
                <Alert severity="error">{submissionError}</Alert>
              )}
              <FormInputText
                name="name"
                register={register}
                control={control}
                muiProps={{
                  id: 'name',
                  label: 'Name',
                  size: 'small',
                  fullWidth: true,
                  margin: 'normal',
                  required: true,
                  error: formState.errors.name != null,
                  helperText: formState.errors?.name?.message ?? undefined,
                }}
              />
              <FormInputText
                name="phoneNumber"
                register={register}
                control={control}
                muiProps={{
                  id: 'phoneNumber',
                  label: 'Phone number',
                  size: 'small',
                  fullWidth: true,

                  required: true,
                  error: formState.errors.phoneNumber != null,
                  helperText:
                    formState.errors?.phoneNumber?.message ?? undefined,
                  margin: 'dense',
                }}
              />
              <FormInputText
                name="email"
                register={register}
                control={control}
                muiProps={{
                  id: 'email',
                  label: 'Email',
                  size: 'small',
                  fullWidth: true,

                  required: true,
                  error: formState.errors.email != null,
                  helperText: formState.errors?.email?.message ?? undefined,
                  margin: 'dense',
                }}
              />
              <FormInputText
                name="message"
                register={register}
                control={control}
                muiProps={{
                  id: 'message',
                  label: 'Messsage',
                  fullWidth: true,
                  margin: 'normal',
                  required: true,
                  error: formState.errors.message != null,
                  helperText: formState.errors?.message?.message ?? undefined,

                  minRows: 5,
                  multiline: true,
                }}
              />
              <Button
                type="submit"
                variant="contained"
                style={{ marginTop: '10px' }}
                disabled={!formState.isDirty}
              >
                submit
              </Button>
            </Box>
          </form>
        </Modal>
        {/* service1 */}
        {service1 && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal sm:h-[90vh] sm:w-7/8 md:h-full flex justify-center items-center m-auto text-black drop-shadow-4xl backdrop-blur backdrop-brightness-50"
          >
            <div class="relative w-full h-full max-w-7xl md:h-auto p-10">
              {/* <!-- Modal content --> */}
              <div class="relative rounded-lg shadow bg-[#F4F8F9]">
                {/* <!-- Modal header --> */}
                <div class="sticky top-0  bg-[#F4F8F9] flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                  <h3 class="text-xl font-semibold text-center m-auto">
                    Digital Fingerprinting service in Surrey, BC
                  </h3>
                  <button
                    type="button"
                    class="text-[#767676] rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={() => service1 && setService1(!service1)}
                  >
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
                {/* <!-- Modal body --> */}
                <div class="p-6 space-y-2 text-left overflow-y-auto max-h-[80vh]">
                  <p class="font-bold">
                    RCMP-accredited, Certified, and Trusted Fingerprinting
                    experts
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    At 1 Choice Fingerprint, we use an optical scanner to
                    capture your fingerprint and make digital files
                    electronically. The next step of the process is to create a
                    package of your electronically captured fingerprints and a
                    duly filled application. This package is emailed to the
                    police services using a secure link. The police then use the
                    information to check the criminal record and offer the
                    status. With no criminal records found, you will be provided
                    with a clear record. We are the most professional and
                    reliable digital fingerprinting service in Surrey, BC, and
                    we can be trusted.
                  </p>
                  <p class="font-bold pt-4">
                    When do you need Digital Fingerprinting?
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    <ul className="list-disc pl-6">
                      <li>
                        When seeking employment with Corporations and Agencies.
                      </li>
                      <li>
                        Filing applications for passports, Visas, Work Permits,
                        Foreign travel, and US waivers.
                      </li>
                      <li>
                        Applying for Pardon also known and Record Suspension.
                      </li>
                      <li>When applying for International adoption.</li>
                      <li>
                        Along with the application for a Security license/ Name
                        change
                      </li>
                      <li>
                        Applying for Permanent residency, immigration, and
                        citizenship.
                      </li>
                    </ul>
                  </p>
                  <p class="font-bold pt-4">
                    How long does it take to process a digital fingerprinting
                    service?
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    The process will be completed faster when you work with an
                    experienced digital fingerprinting service provider in
                    Surrey, BC.
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    <ul className="list-disc pl-6">
                      <li>When no record found - 7-10 working days</li>
                      <li>When record is found - 120 days or more</li>
                    </ul>
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    What are the core requirements for Digital Fingerprinting
                    service? For a smooth process, you must provide two valid
                    government-issued identifications, one of which should be a
                    photo ID. Make sure that the identification is not expired.
                    If you want to mail the results to a third party, you must
                    bring their verified address. Some of the acceptable
                    government ID proofs for the process are:
                    <ul className="list-disc pl-6"></ul>
                    <li>Passport</li>
                    <li>Citizenship Card or Document</li>
                    <li>Immigration Document ( Record of Landing)</li>
                    <li>Driver’s License</li>
                    <li>
                      Permanent Resident card or Record of Landing for
                      Citizenship Applicants
                    </li>
                    <li>Government ID</li>
                    <li>Work Permits</li>
                    <li>Student Permit</li>
                    <li>BC Care Card</li>
                    <li>Certificate of Indian Status</li>
                    Can I check the status of my Fingerprints? Our experts
                    submit all the Fingerprint proceeds to the RCMP office. In
                    case of any queries or requests, you can contact RCMP at
                    CCRTIS-SCICTR@rcmp-grc.gc.ca. We take charge of your process
                    and ensure that you clearly understand our terms of digital
                    fingerprint service in Surrey.
                  </p>
                  <p class="font-bold pt-4">Method of Payment</p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    We accept payments in different modes, including cash, debit
                    cards, E-TFR, and major credit cards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* service2 */}
        {service2 && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden md:inset-0 h-modal sm:h-[90vh] sm:w-7/8 md:h-full flex justify-center items-center m-auto text-black drop-shadow-4xl  backdrop-blur backdrop-brightness-50"
          >
            <div class="relative w-full h-full max-w-7xl md:h-auto p-10">
              {/* <!-- Modal content --> */}
              <div class="relative rounded-lg shadow bg-[#F4F8F9] flex flex-col">
                {/* <!-- Modal header --> */}
                <div class="bg-[#F4F8F9] flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600 flex-grow">
                  <h3 class="text-xl font-semibold text-center m-auto">
                    Security License Application
                  </h3>
                  <button
                    type="button"
                    class="text-[#767676] bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={() => service2 && setService2(!service2)}
                  >
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
                {/* <!-- Modal body --> */}
                <div class="p-6 space-y-2 text-left md:pt-12 overflow-y-auto max-h-[80vh]">
                  <p class="font-bold">
                    RCMP Accredited, Certified and Trusted Fingerprinting
                    experts
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    Once you have cleared the BST I and II examination, the next
                    step is to apply for a security license. Only after you
                    obtain a security license will you be eligible to work as a
                    legal Security license application Service in Surrey, BC. At
                    1 Choice Fingerprint, we help you get a security license and
                    offer customized consultancy. We are a team of professionals
                    who associate with you and help you with the security
                    license application, renewals, and more. We work with
                    individuals and groups to meet their security licensing
                    needs. We record your digital fingerprints and request that
                    RCMP Ottawa mail the results to the Registrar, Security
                    Services Act in Victoria, BC, and duly fill in your
                    application. Once the application is submitted after our
                    check, you can expect the license in 10-15 days.
                  </p>
                  <p class="font-bold pt-4">
                    ID proof requirements for Security License Application
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    The following two ID proofs are acceptable for the process:
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    <ul className="list-disc pl-6">
                      <li>Passport</li>
                      <li>Citizenship Card or Document</li>
                      <li>Immigration Document (Record of Landing)</li>
                      <li>Birth Certificate</li>
                      <li>Driver's License</li>
                      <li>
                        Permanent Resident Card or Record of Landing for
                        Citizenship Applicants
                      </li>
                      <li>Government ID</li>
                      <li>Work Permits</li>
                      <li>Student Permit</li>
                      <li>BC Care Card</li>
                      <li>Certificate of Indian Status</li>
                      <li>Military Family ID card (MFID)</li>
                      <li>Nexus Card</li>
                    </ul>
                  </p>
                  <p class="font-bold pt-4">Method of Payment</p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    We accept payments in different modes, including cash, debit
                    cards, E-TFR, and major credit cards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* service3 */}
        {service3 && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden md:inset-0 h-modal sm:h-[90vh] sm:w-7/8 md:h-full flex justify-center items-center m-auto text-black drop-shadow-4xl  backdrop-blur backdrop-brightness-50"
          >
            <div class="relative w-full h-full max-w-7xl md:h-auto p-10">
              {/* <!-- Modal content --> */}
              <div class="relative rounded-lg shadow bg-[#F4F8F9] flex flex-col">
                {/* <!-- Modal header --> */}
                <div class="bg-[#F4F8F9] flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600 flex-grow">
                  <h3 class="text-xl font-semibold text-center m-auto">
                    Ink & Roll Fingerprinting
                  </h3>
                  <button
                    type="button"
                    class="text-[#767676] bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={() => service3 && setService3(!service3)}
                  >
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
                {/* <!-- Modal body --> */}
                <div class="p-6 space-y-2 text-left md:pt-5 overflow-y-auto max-h-[80vh]">
                  <p class="font-bold">
                    RCMP-accredited, Certified, and Trusted Fingerprinting
                    experts.
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    Conventional Ink & Roll Fingerprinting is still a valid
                    requirement for several processes. If you need traditional
                    ink and roll fingerprinting for obtaining police
                    certificates from other countries, FBI clearances, old
                    passport renewal, making IDs in previous countries, security
                    clearances, or more, we are the right stop.
                  </p>
                  <p class="font-bold pt-4">Method of Payment</p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    We accept payments in different modes, including cash, debit
                    cards, E-TFR, and major credit cards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* service4 */}
        {service4 && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden md:inset-0 h-modal sm:h-[90vh] sm:w-7/8 md:h-full flex justify-center items-center m-auto text-black drop-shadow-4xl  backdrop-blur backdrop-brightness-50"
          >
            <div class="relative w-full h-full max-w-7xl md:h-auto p-10">
              {/* <!-- Modal content --> */}
              <div class="relative rounded-lg shadow bg-[#F4F8F9]">
                {/* <!-- Modal header --> */}
                <div class="bg-[#F4F8F9] flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600 flex-grow">
                  <h3 class="text-xl font-semibold text-center m-auto">
                    Name Change Application
                  </h3>
                  <button
                    type="button"
                    class="text-[#767676] bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={() => service4 && setService4(!service4)}
                  >
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
                {/* <!-- Modal body --> */}
                <div class="p-9 space-y-2 text-left overflow-y-auto max-h-[80vh]">
                  <p className="text-base leading-relaxed text-[#767676]">
                    If you want to change your name legally, you must satisfy a
                    few criteria. At 1 Choice Fingerprint, we offer a
                    professional name change application service in Surrey, BC,
                    with proven results. As an applicant for a name change, you
                    must be:
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    <ul className="list-decimal pl-9">
                      <li>
                        1. An individual that wants to change their name or a
                        child's name under their custody.
                      </li>
                      <li>19 years or older</li>
                      <li>
                        3. A resident of BC for the last three months from the
                        date of applying for name change or a person domiciled
                        in BC for three months before the date of application.
                      </li>
                    </ul>
                  </p>
                  <p class="font-bold pt-4">
                    Criminal Record Check for Name Change
                  </p>
                  <p className="text-base leading-relaxed text-[#767676]">
                    The process of Name Change includes digital fingerprint
                    recording as well. The process is performed by an accredited
                    fingerprinting official offering authorized name correction
                    application services in Surrey, BC, such as 1 Choice
                    Fingerprint. The fingerprints thus taken are forwarded to
                    the RCMP Ottawa for criminal record verification, a critical
                    part of the Name Change process.
                    <br />
                    We take charge of the entire process and submit the
                    fingerprint copy, consent form, and receipt of the amount
                    paid (including the RCMP fee paid) along with your
                    application to the Vital Statistics Agency in Victoria via a
                    secure email link.
                  </p>
                  <p class="font-bold pt-4">Method of Payment</p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    We accept payments in different modes, including cash, debit
                    cards, E-TFR, and major credit cards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* service5 */}
        {service5 && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden md:inset-0 h-modal sm:h-[90vh] sm:w-7/8 md:h-full flex justify-center items-center m-auto text-black drop-shadow-4xl  backdrop-blur backdrop-brightness-50"
          >
            <div class="relative w-full h-full max-w-7xl md:h-auto p-10">
              {/* <!-- Modal content --> */}
              <div class="relative rounded-lg shadow bg-[#F4F8F9] flex flex-col">
                {/* <!-- Modal header --> */}
                <div class="bg-[#F4F8F9] flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600 flex-grow">
                  <h3 class="text-xl font-semibold text-center m-auto">
                    USA Entry Waiver
                  </h3>
                  <button
                    type="button"
                    class="text-[#767676] bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={() => service5 && setService5(!service5)}
                  >
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
                {/* <!-- Modal body --> */}
                <div class="p-6 space-y-2 text-left md:pt-5 overflow-y-auto max-h-[80vh]">
                  <p class="text-base leading-relaxed text-[#767676]">
                    Several restrictions are imposed on specific individuals,
                    preventing them from entering the US. Some common grounds
                    for rejection to entry into the US can be an infectious
                    disease, a criminal record for crimes of moral turpitude,
                    possession of controlled substances, trafficking of human or
                    controlled substances, association with a terrorist group or
                    participation in any terrorist activity, and money
                    laundering accusation. Additionally, if an individual was
                    previously deported from the United States, has a public
                    charge of having limited financial resources, or has
                    overstayed the allowed period in the United States, they can
                    be denied entry to the US at any time. The list includes
                    several other aspects and isn’t limited to the reasons
                    mentioned. If you cannot gain entry into the US, you need to
                    understand and apply for a US Entry Waiver through reliable
                    sources. At 1 Choice Fingerprint, we are a professional team
                    of experts offering USA Entry Waiver services in Surrey, BC,
                    and due guidance and assistance required.
                  </p>
                  <p class="font-bold">
                    When, how, and why should you apply for a US Entry Waiver?
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    Conventional Ink & Roll Fingerprinting is still a valid
                    requirement for several processes. If you need traditional
                    ink and roll fingerprinting for obtaining police
                    certificates from other countries, FBI clearances, old
                    passport renewal, making IDs in previous countries, security
                    clearances, or more, we are the right stop.
                  </p>{' '}
                  <p class="text-base leading-relaxed text-[#767676]">
                    Cross-border security has become a crucial concern that has
                    affected travel across American & Canadian borders. For any
                    individual carrying a criminal record in any country, it is
                    critical to have a copy of the US Entry Waiver to legally
                    travel into the United States of America. Anyone charged
                    with a criminal offence is automatically denied entry into
                    the US without a US Entry Waiver. Irrespective of the final
                    decision on the charges (withdrawn, dismissed, stayed,
                    absolutely or conditionally discharged, or the criminal
                    charges ended up in a conviction), the US Customs Department
                    can reject the entry to the US. The team of 1 Choice
                    Fingerprint, with our professional USA Entry Waiver services
                    in Surrey, BC, can help you get a criminal record check from
                    the RCMP and further complete your application.
                  </p>
                  <p class="font-bold pt-4">
                    Fees for USA Entry Waiver services:
                  </p>
                  Department Of Home Security Fee: <b>$585 USD</b>
                  <br />
                  Application Fee – Varies from case to case.
                  <br />
                  (Rates are subject to change at any time)
                  <br />
                  <p class="font-bold pt-4">Method of Payment</p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    We accept payments in different modes, including cash, debit
                    cards, E-TFR, and major credit cards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* service6 */}
        {service6 && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden md:inset-0 h-modal sm:h-[90vh] sm:w-7/8 md:h-full flex justify-center items-center m-auto text-black drop-shadow-4xl  backdrop-blur backdrop-brightness-50"
          >
            <div class="relative w-full h-full max-w-7xl md:h-auto p-10">
              {/* <!-- Modal content --> */}
              <div class="relative rounded-lg shadow bg-[#F4F8F9] flex flex-col">
                {/* <!-- Modal header --> */}
                <div class="bg-[#F4F8F9] flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600 flex-grow">
                  <h3 class="text-xl font-semibold text-center m-auto">
                    Record Suspension
                  </h3>
                  <button
                    type="button"
                    class="text-[#767676] bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={() => service6 && setService6(!service6)}
                  >
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
                {/* <!-- Modal body --> */}
                <div class="p-6 space-y-2 text-left md:pt-5 overflow-y-auto max-h-[80vh]">
                  <p class="text-base leading-relaxed text-[#767676]">
                    Record Suspension Service in Surrey, BC, also known as
                    Pardon, is an official authorization provided by the
                    Canadian Government that helps people with criminal charges
                    to get a suspension on their criminal record. The
                    authorization applies to all individuals convicted for
                    criminal offences, regardless of the conviction's result –
                    whether charges ended with a sentence or were later
                    withdrawn, dismissed, stayed, absolutely or conditionally, a
                    police certificate states of your harmful contact with them.
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    If you have been criminally convicted, it is an obstacle for
                    you to travel across borders, start a business, or find a
                    job in Canada. However, you can prevent the situation by
                    procuring a Record Suspension. The expert team of 1 Choice
                    Fingerprint will offer professional record suspension
                    services in Surrey, BC, and help you navigate the process
                    and apply for a record suspension. We help you throughout
                    the Record Suspension process, including criminal record
                    search, digital fingerprints, court information retrieval,
                    and relevant documents collection from Canadian government
                    agencies to complete the entire application and process. We
                    make the process hassle-free and offer you all the required
                    assistance.
                  </p>
                  <p class="font-bold">
                    When should you apply for Record Suspension?
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    Some of the occasions that demand for record suspension
                    service include
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    <ul className="list-disc pl-6">
                      <li>Canadian Citizenship</li>
                      <li>Canadian Immigration</li>
                      <li>Volunteer Work</li>
                      <li>Adoption</li>
                      <li>Child's Custody</li>
                      <li>Overseas Visa</li>
                      <li>Employment -Federal/Provincial/Private Sector</li>
                      <li>Liquor License</li>
                      <li>Franchise Purchase</li>
                      <li>School/College Admission</li>
                    </ul>
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    What are the eligibility criteria for Record Suspension?
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    <ul className="list-decimal pl-6">
                      <li>
                        Eligibility: All sentences must be completed. Details:
                        The individual must pay all fines, surcharges, costs,
                        restitution, and compensation orders. The individual
                        must also serve all their time, including parole or
                        statutory release. The individual must also satisfy the
                        probation order.
                      </li>
                      <li>
                        Eligibility: Must have completed the waiting period
                        after completing all sentences. Details: 10 Years – In
                        case of an indictable offence (for a personal injury
                        offence (Section 752 of the Criminal Code) including
                        manslaughter, where a sentence of two or more years was
                        imposed – an indictable sexual offence) 5 years – for a
                        summary offence three years – Conditional discharge.
                      </li>
                    </ul>
                  </p>
                  <p class="font-bold">
                    What are the benefits of the Record Suspension service?
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    You can enjoy certain benefits from record suspension
                    service, including but not limited to: For Canadian
                    Immigration/Citizenship To avoid future accusations, Peace
                    of mind, no disgrace associated with those who have a
                    criminal record, Easy access to education, career, and
                    leisure opportunities (to study, travel, get a job and do
                    any Volunteer work).
                  </p>
                  <p class="font-bold pt-4">Method of Payment</p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    We accept payments in different modes, including cash, debit
                    cards, E-TFR, and major credit cards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* service7 */}
        {service7 && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal sm:h-[90vh] sm:w-7/8 md:h-full flex justify-center items-center m-auto text-black drop-shadow-4xl backdrop-blur backdrop-brightness-50"
          >
            <div class="relative w-full h-full max-w-7xl md:h-auto p-10">
              {/* <!-- Modal content --> */}
              <div class="relative rounded-lg shadow bg-[#F4F8F9]">
                {/* <!-- Modal header --> */}
                <div class="sticky top-0  bg-[#F4F8F9] flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                  <h3 class="text-xl font-semibold text-center m-auto">
                    Police Check Outside Canada
                  </h3>
                  <button
                    type="button"
                    class="text-[#767676] rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={() => service7 && setService7(!service7)}
                  >
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
                {/* <!-- Modal body --> */}
                <div class="p-6 space-y-2 text-left overflow-y-auto max-h-[80vh]">
                  <p class="font-bold">
                    The acceptable identification documents for the process are:
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    <ul className="list-disc pl-6"></ul>
                    <li>BC Driving License</li>
                    <li>Driver's License with a photo from any country</li>
                    <li>BC Identification Card with photo</li>
                    <li>Passport</li>
                    <li>Nexus Card</li>
                    <li>Native Status Card</li>
                    <li>Citizenship Card</li>
                    <li>Permanent Resident Card</li>
                    <li>Military Identification</li>
                    <li>Birth Certificate</li>
                    <li>Firearms License</li>
                    <li>Provincial Health Care Card</li>
                    <li>Pleasure Craft License</li>
                    <li>Pan Card/Aadhaar Card</li>
                    <br />
                    Can I check the status of my Fingerprints?
                    <br />
                    Our experts submit all Fingerprint proceeds to the RCMP
                    office. If you have any queries or requests, you can contact
                    the RCMP at CCRTIS-SCICTR@rcmp-grc.gc.ca. We take charge of
                    your process and ensure that you clearly understand our
                    terms of digital fingerprint service in Surrey.
                    <br />
                    <br />
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    RCMP Police Certificate from Abroad/Overseas/Outside Canada.
                    <br />
                    1 Choice Fingerprint is a trusted Police Check Outside
                    service in Surrey, BC Agency. Our services are aimed at
                    Visitors/Students/Workers seeking Canadian Police Clearance
                    after residing in Canada for six months or more or those who
                    are Canadian residents living abroad. We specialize in
                    converting fingerprints into digital files for RCMP
                    verification. The process takes 8-10 days, and the report is
                    mailed to your Canadian address. We are dedicated to
                    offering you the best solutions and ensuring the results are
                    delivered within the set frame. Understandably, Canadian
                    embassies/consulates worldwide are preoccupied with much
                    work to process fingerprints; hence, we can handle the
                    records. We help you save time, money, and effort. You can
                    get digitized fingerprint files within 72 hours of
                    submission.
                    <br />
                    <br />
                    What are the acceptable identifications for Police checks
                    outside Canada?
                    <br />
                    For the process, you must submit two pieces of
                    identification proof duly notarized by a public notary, a
                    fingerprint form signed by an official or private agency, a
                    picture, and a consent form. All the files can be emailed to
                    us for verification, and then you can send the original
                    documents by post or courier upon hearing from us.
                    <br />
                  </p>
                  <p class="font-bold pt-4">Method of Payment</p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    We accept payments in different modes, including cash, debit
                    cards, E-TFR, and major credit cards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* service8 */}
        {service8 && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal sm:h-[90vh] sm:w-7/8 md:h-full flex justify-center items-center m-auto text-black drop-shadow-4xl backdrop-blur backdrop-brightness-50"
          >
            <div class="relative w-full h-full max-w-7xl md:h-auto p-10">
              {/* <!-- Modal content --> */}
              <div class="relative rounded-lg shadow bg-[#F4F8F9]">
                {/* <!-- Modal header --> */}
                <div class="sticky top-0  bg-[#F4F8F9] flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                  <h3 class="text-xl font-semibold text-center m-auto">
                    Police Certificate (ECPIC)
                  </h3>
                  <button
                    type="button"
                    class="text-[#767676] rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={() => service8 && setService8(!service8)}
                  >
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
                {/* <!-- Modal body --> */}

                <div class="p-6 space-y-2 text-left overflow-y-auto max-h-[80vh]">
                  <p class="text-base leading-relaxed text-[#767676]">
                    Suppose you leave Canada and seek employment with a foreign
                    private sector company. In that case, you must get a
                    criminal record clearance provided through a Police
                    Certificate service in Surrey, BC. Typical cases for Police
                    certificates are visa applicants, foreign travel, and
                    foreign work permits. You may also require a Police
                    certificate for private sector employment, renting a
                    house/basement, applying for school admission, etc, as and
                    when needed. If you have a criminal conviction record, the
                    experts at 1 Choice Fingerprint advise you not to apply for
                    a Police Certificate. Our specialists then send the reports
                    to police authorities electronically (ECPIC).
                  </p>
                  <p class="text-base leading-relaxed text-[#767676] font-bold">
                    What is the acceptable identification for a Police
                    Certificate (ECPIC)?
                    <br />
                    You will need two numbers of valid government ID proofs for
                    the process. These ID proofs can be:
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    <ul className="list-disc pl-6"></ul>
                    <li>BC Driving License</li>
                    <li>Driver's License with a photo from any country</li>
                    <li>BC Identification Card with photo</li>
                    <li>Passport</li>
                    <li>Nexus Card</li>
                    <li>Native Status Card</li>
                    <li>Canadian Citizenship Card</li>
                    <li>Permanent Resident Card</li>
                    <li>Military Identification</li>
                    <li>Birth Certificate</li>
                    <li>Firearms License</li>
                    <li>Provincial Health Care Card</li>
                    <li>ICBC car insurance policy</li>
                    <br />
                  </p>
                  <p class="text-base leading-relaxed text-[#767676] font-bold">
                    Why should you pay importance to Criminal Record Checks as
                    an employer?
                  </p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    <ul className="list-disc pl-6"></ul>
                    <li>
                      It is an adequate prediction of the employee's
                      performance.
                    </li>
                    <li>Identification of unacceptable behaviours.</li>
                    <li>Minimized risk of negligent hiring & litigation.</li>
                    <li>Minimized workplace violence.</li>
                    <li>Minimized turnover & hiring costs.</li>
                    <li>Quick recruitment process</li>
                  </p>
                  <p class="font-bold pt-4">Method of Payment</p>
                  <p class="text-base leading-relaxed text-[#767676]">
                    We accept payments in different modes, including cash, debit
                    cards, E-TFR, and major credit cards
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Satnam */}
        {g1Modal && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden md:inset-0 h-modal sm:h-[90vh] sm:w-7/8 md:h-full flex flex-col justify-center items-center m-auto text-black drop-shadow-4xl  backdrop-blur backdrop-brightness-50"
          >
            <div className="w-auto h-[0.1em] flex items-center justify-center">
              <img
                src={require('./g1.jpg')}
                class="object-contain h-[52rem]"
                alt="Outdoor Picture showing North Enterence to 1 Choice Fingerpriint Office"
              ></img>
            </div>
          </div>
        )}
        {g2Modal && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden md:inset-0 h-modal sm:h-[90vh] sm:w-7/8 md:h-full flex flex-col justify-center items-center m-auto text-black drop-shadow-4xl  backdrop-blur backdrop-brightness-50"
          >
            <div className="w-auto h-[0.1em] flex items-center justify-center">
              <img
                src={require('./g2.jpg')}
                class="object-contain h-[52rem]"
                alt="Outdoor Picture showing East Enterence to 1 Choice Fingerpriint Office"
              ></img>
            </div>
          </div>
        )}
        {g3Modal && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden md:inset-0 h-modal sm:h-[90vh] sm:w-7/8 md:h-full flex flex-col justify-center items-center m-auto text-black drop-shadow-4xl  backdrop-blur backdrop-brightness-50"
          >
            <div className="w-auto h-auto flex items-center justify-center">
              <img
                src={require('./g3.png')}
                class="object-contain h-[52rem]"
                alt="2nd Floor Picture showing Entry Gage 206 for the office of 1 Choice Fingerpriint"
              ></img>
            </div>
          </div>
        )}
        {g4Modal && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden md:inset-0 h-modal sm:h-[90vh] sm:w-7/8 md:h-full flex flex-col justify-center items-center m-auto text-black drop-shadow-4xl  backdrop-blur backdrop-brightness-50"
          >
            <div className="w-auto h-[0.1em] flex items-center justify-center">
              <img
                src={require('./g4.jpg')}
                class="object-contain h-[52rem]"
                alt="Picture showing Reception after enting from 206 for the office of 1 Choice Fingerpriint"
              ></img>
            </div>
          </div>
        )}
        {g5Modal && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden md:inset-0 h-modal sm:h-[90vh] sm:w-7/8 md:h-full flex flex-col justify-center items-center m-auto text-black drop-shadow-4xl  backdrop-blur backdrop-brightness-50"
          >
            <div className="w-auto h-[0.1em] flex items-center justify-center">
              <img
                src={require('./g5.jpg')}
                class="object-contain h-[52rem]"
                alt="Inside Picture showing cornor view of Office of 1 Choice fingerprint"
              ></img>
            </div>
          </div>
        )}
        {g6Modal && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden md:inset-0 h-modal sm:h-[90vh] sm:w-7/8 md:h-full flex flex-col justify-center items-center m-auto text-black drop-shadow-4xl  backdrop-blur backdrop-brightness-50"
          >
            <div className="w-auto h-[0.1em] flex items-center justify-center">
              <img
                src={require('./g6.jpg')}
                class="object-contain h-[52rem]"
                alt="Inside Picture showing whole Office of 1 Choice fingerprint"
              ></img>
            </div>
          </div>
        )}
        {/* Landing page */}
        <div
          id={'home'}
          className={`sm:${open ? 'hidden' : ''} flex space-x-50 w-full bg-[#002654]`}
          style={{ backgroundImage: `url(${require('./rcmp-banner.png')})` }}
        >
          <div className="text-left flex flex-col justify-center my-auto md:mx-auto sm:mx-5 md:pl-8 sm:pl-2 sm:pb-12 sm:pt-4">
            <h1 className="font-bold text-2xl md:text-7xl xl:text-7xl sm:pt-12 text-[#cd1a3b] ">
              RCMP
            </h1>
            <p className="text-lg sm:pt-2 font-semibold md:text-4xl lg:text-4xl">
              ACCREDITED FINGERPRINT SPECIALISTS
            </p>
            <ul class="list-disc text-md ml-6 leading-relaxed md:text-2xl mt-3 lg:text-2xl">
              <li className="sm:pt-4">
                Get Instant digital fingerprints and sort your crucial purposes.
              </li>
              <li className="sm:pt-4">
                RCMP accredited Police verification experts at your service.
              </li>
              <li className="sm:pt-4">
                Best fingerprinting company in the whole Canada.
              </li>
            </ul>
            <table border="0" wide="100%">
              <tr>
                <td width="15%" align="center" valign="center">
                  <div className="d-flex marginTop-2">
                    {' '}
                    <img
                      class="w-20 h-40 "
                      src={dlogo}
                      align="left"
                      alt="1 Choice Fingerprint Services Ltd. Logo"
                    />
                  </div>
                </td>
                <td width={'85%'} valign="top">
                  <p className="text-sm md:text-2xl font-extrabold text-blue-200 pt-4">
                    <br />

                    <a
                      href="https://www.google.com/maps/dir//12885+80+Ave,+Unit+%23206+on+2nd+floor(opposite+Chatr+Mobile+Payal+business+centre,+Surrey,+BC+V3W+0E6/@49.1493403,-122.9481858,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x5485d9df9bf51867:0x37f39aa957c29d65!2m2!1d-122.8657854!2d49.1493696?entry=ttu"
                      id="my-tooltip-1"
                      className="text-decoration-none hover-color:red"
                    >
                      Address:
                      <br />
                      &emsp;Unit 206-12885 80 Avenue,
                      <br />
                      &emsp;(Payal Business Centre),
                      <br />
                      &emsp; SURREY, BC V3W 0E6, CANADA
                    </a>
                  </p>
                  <Tooltip
                    style={{
                      backgroundColor: 'rgb(0, 255, 30)',
                      color: '#222',
                    }}
                    data-tooltip-offset={-10}
                    anchorSelect="#my-tooltip-1"
                    content="Click Here to Open This Address In Google Maps"
                  />
                </td>
              </tr>
              <tr>
                <td width={'15%'}>
                  <div class="d-block d-sm-none">
                    <img
                      className="sm:px-0"
                      class="w-10 h-10"
                      src={timings}
                      align="left"
                      alt="Opening Timings"
                    />
                  </div>
                </td>
                <td width={'85%'}>
                  <div class="d-block d-sm-none">
                    <span className="text-sm md:text-2xl font-extrabold text-blue-200 pt-4">
                      Monday - Saturday (09 AM - 05 PM)
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td width={'15%'}>&nbsp;</td>
                <td width={'85%'}>
                  {' '}
                  <p className="text-sm md:text-2xl font-extrabold text-blue-200 pt-4">
                    Tel:{' '}
                    <a href="tel:+1 604-593-8951" className="hover:underline">
                      +1 604-593-8951
                    </a>
                    <br />
                    E-Mail:{' '}
                    <a
                      href="mailto:info@1stchoicefingerprint.ca"
                      className="hover:underline"
                    >
                      info@1stchoicefingerprint.ca
                    </a>
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div className="sm:hidden md:block right-0 w-2/4 ml-auto flex justify-center items-center">
            <p>&nbsp;</p>
          </div>
        </div>
        {/* Our services */}
        <div
          id={'services'}
          className={`sm:${open ? 'hidden' : ''} flex flex-col justify-center items-center text-center md:mb-12 md:pb-10 md:px-5 md:h-screen sm:p-5`}
        >
          <p className="text-3xl text-[#353535] font-bold mb-10 mt-10 sm:pt-5 md:mb-12 md:mt-12 md:pt-12 md:pb-5">
            OUR SERVICES
          </p>
          <div className="grid grid-flow-row mb-7 grid-rows-1 justify-items-center sm:grid-cols-2 md:grid-cols-4 md:grid-rows-2">
            <div
              className="bg-[#0E4F9C] p-7 flex flex-col justify-center items-center rounded-3xl w-2/3 md:h-[15rem] sm:w-3/4 sm:h-[12em] md:mr-3 hover:contrast-50"
              onClick={() => setService1(!service1)}
            >
              <img src={require('./service8.png')}></img>
              <p className="pt-4 sm:text-sm md:text-2xl">
                Digital
                <br />
                Fingerprinting
              </p>
            </div>
            <div
              className="bg-[#0E4F9C] p-7 flex flex-col justify-center items-center rounded-3xl w-2/3 md:h-[15rem] sm:w-3/4 sm:h-[12em] md:mr-3 hover:contrast-50"
              onClick={() => setService7(!service7)}
            >
              <img src={require('./services1.png')}></img>
              <p className="pt-4 sm:text-sm md:text-2xl">
                Police Check
                <br />
                Outside Canada
              </p>
            </div>
            <div
              className="bg-[#0E4F9C] p-7 flex flex-col justify-center items-center rounded-3xl w-2/3 md:h-[15rem] sm:mt-5  md:mt-0 sm:w-3/4 sm:h-[12em] md:mr-3 hover:contrast-50"
              onClick={() => setService8(!service8)}
            >
              <img src={require('./service7.png')}></img>
              <p className="pt-4 sm:text-sm md:text-2xl">
                Police Certificate
                <br />
                (ECPIC)
              </p>
            </div>
            <div
              className="bg-[#0E4F9C] p-7 flex flex-col justify-center items-center rounded-3xl w-2/3 md:h-[15rem] sm:mt-5  md:mt-0 sm:w-3/4 sm:h-[12em] md:mr-3 hover:contrast-50"
              onClick={() => setService4(!service4)}
            >
              <img src={require('./nameChange.png')}></img>
              <p className="pt-4 sm:text-sm md:text-2xl">
                Name Change Application
              </p>
            </div>
            <div
              className="bg-[#0E4F9C] p-7 flex flex-col justify-center items-center rounded-3xl w-2/3 md:h-[15rem] sm:w-3/4 sm:h-[12em] sm:mt-5 md:mt-9 hover:contrast-50"
              onClick={() => setService2(!service2)}
            >
              <img src={require('./securityLicense.png')}></img>
              <p className="pt-4 sm:text-sm md:text-2xl">
                Security License Application
              </p>
            </div>
            <div
              className="bg-[#0E4F9C] p-7 flex flex-col justify-center items-center rounded-3xl w-2/3 md:h-[15rem] sm:w-3/4 sm:h-[12em] sm:mt-5 md:mt-9 md:mr-3 hover:contrast-50"
              onClick={() => setService3(!service3)}
            >
              <img src={require('./inkRolling.png')}></img>
              <p className="pt-4 sm:text-sm md:text-2xl">
                {'Ink & Roll'}
                <br />
                Fingerprinting
              </p>
            </div>
            <div
              className="bg-[#0E4F9C] p-7 flex flex-col justify-center items-center rounded-3xl w-2/3 md:h-[15rem] sm:w-3/4 sm:h-[12em] sm:mt-5 md:mt-9 md:mr-3 hover:contrast-50"
              onClick={() => setService5(!service5)}
            >
              <img src={require('./usa.png')}></img>
              <p className="text-2xl pt-4 sm:text-sm md:text-2xl">
                USA
                <br />
                Entry Waiver
              </p>
            </div>
            <div
              className="bg-[#0E4F9C] p-7 flex flex-col justify-center items-center rounded-3xl w-2/3 md:h-[15rem] sm:w-3/4 sm:h-[12em] sm:mt-5 md:mt-9 hover:contrast-50"
              onClick={() => setService6(!service6)}
            >
              <img src={require('./recSuspension.png')}></img>
              <p className="text-2xl pt-4 sm:text-sm md:text-2xl">
                Record
                <br />
                Suspension
              </p>
            </div>
          </div>
        </div>
        {/* About Us */}
        <div id={'about-us'}></div>
        <div
          className={`sm:${open ? 'hidden' : ''} bg-[#F0F6FF] w-full text-[#353535] text-left md:pt-7 pb-12 md:h-fit flex items-center justify-center`}
        >
          <div className="flex-col justify-center items-center place-content-center sm:p-5 md:p-3 md:pt-7 md:h-fit">
            <p className="sm:text-3xl md:text-4xl font-bold text-center sm:pb-0 md:pb-20 sm:pt-12 md:pt-16">
              ABOUT US
            </p>
            <div className="flex flex-col items-center md:items-start md:flex-row md:space-x-[2rem] md:pt-7 sm:pt-2">
              <div className="flex flex-col p-5 w-full items-center md:items-end md:w-7/8 md:p-0 md:py-3">
                <img
                  src={require('./about1.png')}
                  className="sm:w-full md:w-2/3"
                ></img>
                <div className="w-full md:w-2/3  md:h-1/2">
                  <p className="sm:text-xl md:text-3xl font-bold pb-5">
                    Digital fingerprinting, name change, police verification,
                    and other services
                  </p>
                  <p className="sm:text-sm md:text-lg">
                    We offer our services in Surrey, Delta, Vancouver, Richmond,
                    Burnaby, New Westminster, White Rock, Mission, Abbotsford,
                    Chilliwack, all BC, and even Canada-wide.
                    <br />
                    <br />
                    Our CPIC services are offered with a guaranteed 4-hour
                    clearance. We offer Multilanguage services at the most
                    budget-friendly rates ever.
                  </p>
                </div>
              </div>
              <div className="flex flex-col p-5 md:p-0 sm:items-center sm:w-full sm:pt-2 md:pt-0 md:items-start md:w-7/8 md:py-3 md:pb-16">
                <div className="w-full md:w-2/3 md:h-1/2">
                  <p className="sm:text-xl md:text-3xl font-bold sm:pb-5 md:pb-6">
                    RCMP digital fingerprinting service in Surrey, BC
                  </p>
                  <p className="sm:text-sm md:text-lg">
                    1 Choice Fingerprint Service is an RCMP-accredited
                    fingerprinting agency that works on behalf of the Police
                    service. As a lawful fingerprinting and identification
                    organization, we comply with all federal, provincial, and
                    municipal privacy rules, laws, and regulations.
                    <br />
                    <br />
                    Because we never compromise on quality and security
                    standards, we are one of the most trusted agencies for
                    criminal history screening and police verification. We work
                    for individuals and organizations, offering the quick and
                    reliable RCMP digital fingerprinting service in Surrey, BC.
                  </p>
                </div>
                <img
                  src={require('./about2.png')}
                  className="w-full sm:pt-3 md:pt-4 md:w-2/3 md:pb-16"
                ></img>
              </div>
            </div>
          </div>
        </div>
        {/* Why us */}
        <div
          id={'whyUs'}
          className={`sm:${open ? 'hidden' : ''} bg-[#042e6d] w-full flex items-center justify-center md:h-min`}
          style={{
            backgroundImage: `url(${require('./WhyUs-Block-Background.png')})`,
          }}
        >
          <div className="sm:hidden md:block md:w-1/2 md:relatie md:z-0 md:mr-0 md:ml-auto md:h-screen"></div>
          <div className="flex-col items-center justify-center m-auto md:absolute md:z-10 md:h-fit sm:p-3 md:p-0">
            <p className="text-5xl font-bold text-center sm:pb-3 sm:pt-12 md:pb-20 text-[#ffffff]">
              WHY US
            </p>
            <div className="grid md:grid-rows-2 md:grid-cols-3  md:gap-y-1 text-black text-left flex items-center place-items-center w-3/4 md:pt-12 mx-auto sm:pb-10">
              <div className="bg-[#FFFFFF] flex-col items-center justify-center col-span-1 md:px-7 sm:px-3 row-span-1 md:w-4/5 md:pb-7 sm:pb-12 rounded-xl sm:w-full md:mb-10 sm:h-4/5">
                <img src={require('./whyus1.png')}></img>
                <p className="md:text-xl font-bold md:pt-4 sm:text-md">
                  RCMP-CCRTIS ACCREDITED
                </p>
                <hr className="bg-[#042e6d] h-2"></hr>
                <p className="md:text-sm md:pt-3 sm:text-xs sm:pt-2">
                  A fully RCMP-CCRTIS accredited agency is working towards your
                  fingerprinting needs. We are qualified and approved across
                  Canada.
                </p>
              </div>
              <div className="bg-[#FFFFFF] flex-col items-center justify-center col-span-1 md:px-7 sm:px-3 row-span-1 md:w-4/5 md:pb-7 sm:pb-12 rounded-xl sm:w-full md:mb-10 sm:h-4/5">
                <img src={require('./whyus2.png')}></img>
                <p className="md:text-xl font-bold md:pt-4 sm:text-md">
                  EXPERIENCED & RELIABLE
                </p>
                <hr className="bg-[#042e6d] h-2"></hr>
                <p className="md:text-sm md:pt-3 sm:text-xs sm:pt-2">
                  Highly trained and certified technicians at work to offer
                  top-notch and reliable results.
                </p>
              </div>
              <div className="bg-[#FFFFFF] flex-col items-center justify-center col-span-1 md:px-7 sm:px-3 row-span-1 md:w-4/5 md:pb-7 sm:pb-12 rounded-xl sm:w-full md:mb-10 sm:h-4/5">
                <img src={require('./whyus3.png')}></img>
                <p className="md:text-xl font-bold md:pt-4 sm:text-md">
                  FAST AND GLOBAL PRESENCE
                </p>
                <hr className="bg-[#042e6d] h-2"></hr>
                <p className="md:text-sm md:pt-3 sm:text-xs sm:pt-2">
                  Services are available in various countries worldwide,
                  including the UK, USA, New Zealand, Canada, Australia, and
                  more.
                </p>
              </div>
              <div className="bg-[#FFFFFF] flex-col items-center justify-center col-span-1 md:px-7 sm:px-3 row-span-1 md:w-4/5 md:pb-7 sm:pb-12 rounded-xl sm:w-full md:mb-10 sm:h-4/5">
                <img src={require('./whyus4.png')}></img>
                <p className="md:text-xl font-bold md:pt-4 sm:text-md">
                  SECURE & HIGH-QUALITY SERVICES
                </p>
                <hr className="bg-[#042e6d] h-2"></hr>
                <p className="md:text-sm md:pt-3 sm:text-xs sm:pt-2">
                  There is no compromise on quality: Premium Canadian-owned and
                  operated company.
                </p>
              </div>
              <div className="bg-[#FFFFFF] flex-col items-center justify-center col-span-1 md:px-7 sm:px-3 row-span-1 md:w-4/5 md:pb-7 sm:pb-12 rounded-xl sm:w-full md:mb-10 sm:h-4/5">
                <img src={require('./whyus5.png')}></img>
                <p className="md:text-xl font-bold md:pt-4 sm:text-md">
                  COMPETITIVE RATES
                </p>
                <hr className="bg-[#042e6d] h-2"></hr>
                <p className="md:text-sm md:pt-3 sm:text-xs sm:pt-2">
                  The most cost-effective digital fingerprints and police
                  verification services anywhere in Canada.
                </p>
              </div>
              <div className="bg-[#FFFFFF] flex-col items-center justify-center col-span-1 md:px-7 sm:px-3 row-span-1 md:w-4/5 md:pb-7 sm:pb-12 rounded-xl sm:w-full md:mb-10 sm:h-4/5">
                <img src={require('./whyus6.png')}></img>
                <p className="md:text-xl font-bold md:pt-4 sm:text-md">
                  POLICE VERIFICATION SERVICES WORLDWIDE
                </p>
                <hr className="bg-[#042e6d] h-2"></hr>
                <p className="md:text-sm md:pt-3 sm:text-xs sm:pt-2">
                  Get Police verification reports instantly, irrespective of
                  your global presence.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Gallery */}
        <div
          className={`sm:${open ? 'hidden' : ''} w-full flex justify-center items-center m-auto`}
        >
          <div className="flex-col justify-center items-center m-auto">
            <p className="text-2xl font-bold text-center pt-12 md:pb-12 md:text-4xl text-[#353535]">
              OUR GALLERY
            </p>
            <div className="grid sm:grid-rows-6 sm:grid-cols-1 md:grid-rows-2 md:grid-cols-3 sm:gap-y-2 md:gap-y-3 md:gap-x-1 place-items-center m-auto sm:p-4 md:pb-16 md:p-12">
              <div
                className="sm:w-full md:w-2/3  md:h-[12rem]"
                onClick={() => setG1Modal(true)}
              >
                <img
                  src={require('./g1.jpg')}
                  alt="Outdoor Picture showing North Enterence to 1 Choice Fingerpriint Office"
                ></img>
              </div>
              <div
                className="sm:w-full md:w-2/3  md:h-[12rem]"
                onClick={() => setG2Modal(true)}
              >
                <img
                  src={require('./g2.jpg')}
                  alt="Outdoor Picture showing East Enterence to 1 Choice Fingerpriint Office"
                ></img>
              </div>
              <div
                className="sm:w-full sm:h-full md:w-2/3  md:h-[12rem] flex justify-center items-center overflow-y-hidden relative"
                onClick={() => setG3Modal(true)}
              >
                <img
                  alt="2nd Floor Picture showing Entry Gage 206 for the office of 1 Choice Fingerpriint"
                  src={require('./g3.jpg')}
                  class="absolute md:h-[42rem] md:w-150rem"
                ></img>
              </div>
              <div
                className="sm:w-full sm:h-full md:w-2/3  md:h-[12rem] flex justify-center items-center overflow-y-hidden relative md:mt-4"
                onClick={() => setG4Modal(true)}
              >
                <img
                  alt="Picture showing Reception after enting from 206 for the office of 1 Choice Fingerpriint"
                  src={require('./g4.jpg')}
                  class="absolute md:h-[62rem]"
                ></img>
              </div>
              <div
                className="sm:w-full md:w-2/3  md:h-[12rem]  md:mt-4"
                onClick={() => setG5Modal(true)}
              >
                <img
                  src={require('./g5.jpg')}
                  alt="Inside Picture showing cornor view of Office of 1 Choice fingerprint"
                  class="absolute md:h-[11rem]"
                ></img>
              </div>
              <div
                className="sm:w-full md:w-2/3  md:h-[12rem]"
                onClick={() => setG6Modal(true)}
              >
                <img
                  src={require('./g6.jpg')}
                  alt="Inside Picture showing whole Office of 1 Choice fingerprint"
                  class="absolute md:h-[11rem]"
                ></img>
              </div>
            </div>
          </div>
        </div>
        {/* Reviews */}
        <div
          id={'reviews'}
          className={`sm:${open ? 'hidden' : ''} bg-[#F0F6FF] w-full text-black pb-12 flex justify-center items-center md:h-screen pt-2`}
          style={{
            backgroundImage: `url(${require('./WhyUs-Block-Background.png')})`,
          }}
        >
          <div className="sm:hidden md:bg-[#cce6ff] md:flex md:justify-center md:items-center md:w-3/4 md:h-2/4 md:relative md:z-0"></div>
          <div className="sm:static md:flex-col md:absolute md:z-10 sm:p-5 md:p-0">
            <p className="text-2xl font-bold text-center pt-2 md:pb-12 md:text-4xl text-[#ffffff]">
              WHAT OUR VALUED CUSTOMERS SAY ABOUT US
            </p>
            <div className="bg-[#FFFFFF] flex-col justify-center items-center p-5 mx-auto mb-12 mt-12 w-1/2 md:w-1/5 rounded-xl shadow-xl">
              <p className="font-bold">Google rating score:</p>
              <p className="font-bold">5 of 5</p>
              <div className="flex flex-col items-center md:pt-2">
                <StarsRating
                  count={5}
                  size={24}
                  value={5}
                  color2={'#ffd700'}
                  edit={false}
                />
              </div>
              <p>
                Based on <b>500+</b> reviews
              </p>
            </div>
            <div className="grid place-content-center mx-auto gap-x-12 gap-y-5 md:gap-y-0 md:grid-rows-1 md:grid-cols-3 md:w-3/4 md:pb-10">
              <div className="bg-[#FFFFFF] flex-col items-center justify-center p-5 w-4/5 mx-auto h-6/7 md:h-7/8 rounded-xl shadow-xl">
                <div className="inline-flex h-1/3 space-x-[3rem] md:space-x-12 md:h-1/6">
                  <div className="flex">
                    <img src={require('./firstLetter.png')}></img>
                    <div className="ml-2">
                      <p className="font-bold text-sm md:text-lg">
                        Aish Sandhu
                      </p>
                      <p className="font-thin text-xs md:text-m">2022-07-18</p>
                    </div>
                  </div>
                  <img src={require('./g.png')}></img>
                </div>
                <div className="flex flex-col items-center md:pt-5">
                  <StarsRating
                    count={5}
                    size={24}
                    value={5}
                    color2={'#ffd700'}
                    edit={false}
                  />
                </div>
                <p className="sm:text-sm text-left px-1 md:px-4 md:text-lg md:pt-4">
                  Five stars all the way! The service, the conversation between
                  us and the overall experience were outstanding.👍
                </p>
              </div>
              <div className="bg-[#FFFFFF] flex-col items-center justify-center p-5 w-4/5 mx-auto h-6/7 md:h-7/8 rounded-xl shadow-xl">
                <div className="inline-flex h-1/3 space-x-[3rem] md:space-x-12 md:h-1/6">
                  <div className="flex">
                    <img src={require('./firstLetter.png')}></img>
                    <div className="ml-2">
                      <p className="font-bold text-sm md:text-lg">
                        Amanpreet Kaur
                      </p>
                      <p className="font-thin text-xs md:text-m">2024-07-17</p>
                    </div>
                  </div>
                  <img src={require('./g.png')}></img>
                </div>
                <div className="flex flex-col items-center md:pt-5">
                  <StarsRating
                    count={5}
                    size={24}
                    value={5}
                    color2={'#ffd700'}
                    edit={false}
                  />
                </div>
                <p className="sm:text-sm text-left px-1 md:px-4 md:text-lg md:pt-4">
                  The service was so genuine, excellent and affordable. Staff
                  was very cooperative and polite.😊
                </p>
              </div>
              <div className="bg-[#FFFFFF] flex-col items-center justify-evenly p-5 w-4/5 mx-auto h-6/7 md:h-7/8 rounded-xl shadow-xl">
                <div className="inline-flex h-1/3 space-x-[3rem] md:space-x-12 md:h-1/6">
                  <div className="flex">
                    <img src={require('./firstLetter.png')}></img>
                    <div className="ml-2">
                      <p className="font-bold text-sm md:text-lg break-words">
                        Kiran Malhi
                      </p>
                      <p className="font-thin text-xs md:text-m">2024-07-11</p>
                    </div>
                  </div>
                  <img src={require('./g.png')}></img>
                </div>
                <div className="flex flex-col items-center md:pt-5">
                  <StarsRating
                    count={5}
                    size={24}
                    value={5}
                    color2={'#ffd700'}
                    edit={false}
                  />
                </div>
                <p className="sm:text-sm text-left px-1 md:px-4 md:text-lg md:pt-4">
                  The services are super nice! The prices are fair. The owner is
                  really knowledgeable, professional and patient.
                </p>
              </div>
            </div>
            <button className="bg-[#011B42] p-3 text-lg text-[#E0EEFF] rounded-xl md:mt-10 sm:mt-7">
              <a
                href="https://www.google.com/search?q=first+choice+fingerprinting&rlz=1C5CHFA_enCA983CA983&ei=9mylY8SKKfCC0PEPitaYoAY&ved=0ahUKEwjE3ZuPr4_8AhVwATQIHQorBmQQ4dUDCA8&uact=5&oq=first+choice+fingerprinting&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIOCC4QrwEQxwEQgAQQ6gQyAggmMgUIABCGAzIFCAAQhgMyBQgAEIYDMgUIABCGAzoKCAAQRxDWBBCwAzoHCAAQsAMQQzoMCC4QyAMQsAMQQxgBOg8ILhDUAhDIAxCwAxBDGAE6CggAELEDEIMBEEM6EQguEIAEELEDEIMBEMcBENEDOgUIABCABDoRCC4QgwEQxwEQsQMQ0QMQgAQ6CwgAEIAEELEDEIMBOhAILhCxAxDHARDRAxBDEOoEOgQIABBDOg0ILhDHARDRAxDUAhBDOg0ILhCxAxDHARDRAxBDOgcILhCxAxBDOgcIABCxAxBDOhAILhDHARDRAxDUAhBDEOoEOgoILhCxAxDUAhBDOgsILhCABBCxAxCDAToKCC4Q1AIQQxDqBDoKCC4QxwEQ0QMQQzoHCC4Q1AIQQzoICAAQgAQQsQM6CgguELEDEIMBEEM6CwguEIAEEMcBEK8BOgUIABCxAzoUCC4QgwEQxwEQsQMQ0QMQgAQQ6gQ6DgguELEDEIMBEMcBEK8BOggIABCxAxCDAToOCC4QxwEQrwEQkQIQ6gQ6DgguEK8BEMcBEJECEOoEOgsILhCvARDHARCABDoGCAAQFhAeSgQIQRgASgQIRhgBUO0KWNVIYJFKaANwAXgAgAFpiAGoDpIBBDI2LjGYAQCgAQHIARLAAQHaAQYIARABGAg&sclient=gws-wiz-serp#lrd=0x5485d9df9bf51867:0x37f39aa957c29d65,1,,,"
                target={'_blank'}
              >
                Read more
              </a>
            </button>
          </div>
        </div>
        {/* footer */}
        {/* <div id={'contact'} class="hidden"></div> */}
        <footer
          className={`sm:${open ? 'hidden' : ''} p-7 sm:p-6 bg-[#172E49] w-full sm:pt-7`}
        >
          <div className="sm:flex sm:flex-col sm:space-x-0 md:flex md:flex-row md:justify-around md:space-x-12 m-auto md:p-7 sm:pt-6">
            <div className="bg-[#FFFFFF] flex flex-col text-left p-7 md:w-1/5 space-y-5 rounded-lg">
              <a href="" className="float-left">
                <img
                  src={require('./logo.png')}
                  className="h-9 mr-3 sm:h-9 "
                  alt="1 Choice Fingerprint Logo"
                />
              </a>
              <p className="text-sm font-semibold text-[#565656]">
                1 Choice Fingerprint is an RCMP accredited fingerprint agency
                that work on behalf of a Police service.
              </p>
              <p className="text-sm font-extrabold text-[#565656]">
                {' '}
                Unit#206, 12885 80 Avenue (Payal Business Centre), Surrey, BC
                V3W 0E6, Canada
              </p>
              <p className="text-sm font-extrabold text-[#565656] hover:underline">
                <a href="tel:+1 604-593-8951" className="hover:underline">
                  +1 604-593-8951
                </a>
              </p>
              <p className="text-sm font-extrabold text-[#565656] hover:underline">
                <a
                  href="mailto:info@1stchoicefingerprint.ca"
                  className="hover:underline"
                >
                  info@1stchoicefingerprint.ca
                </a>
              </p>
            </div>
            <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-2 text-left md:pl-12 sm:pt-7 md:pt-0">
              <div>
                <h2 class="mb-4 text-sm font-semibold text-[#D1E6FF] md:text-xl">
                  Links
                </h2>
                <ul class="list-disc ml-5 sm:space-y-1 md:space-y-4">
                  <li>
                    <a
                      href="https://www.rcmp-grc.gc.ca/en/where-do-get-a-criminal-record-check"
                      class="hover:underline sm:text-sm md:text-base"
                      target="_blank"
                    >
                      Where do I get get a Criminal Record
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.rcmp-grc.gc.ca/en/steps-getting-a-certified-criminal-record-check"
                      class="hover:underline  sm:text-sm md:text-base"
                      target="_blank"
                    >
                      Steps for getting a Certified Criminal Record
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.usentrywaiverservices.com/"
                      class="hover:underline  sm:text-sm md:text-base"
                      target="_blank"
                    >
                      Denied entery to USA
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2 class="mb-4 text-sm font-semibold text-[#D1E6FF] md:text-xl">
                  Our Services
                </h2>
                <ul class="list-disc ml-5 sm:space-y-1 md:space-y-4">
                  <li>
                    <a
                      class="hover:underline  sm:text-sm md:text-base"
                      onClick={() => setService1(!service1)}
                    >
                      Digital Fingerprinting
                    </a>
                  </li>
                  <li>
                    <a
                      class="hover:underline sm:text-sm md:text-base"
                      onClick={() => setService2(!service2)}
                    >
                      Security License Application
                    </a>
                  </li>
                  <li>
                    <a
                      class="hover:underline sm:text-sm md:text-base"
                      onClick={() => setService3(!service3)}
                    >
                      Ink & Roll Fingerprinting
                    </a>
                  </li>
                  <li>
                    <a
                      class="hover:underline  sm:text-sm md:text-base"
                      onClick={() => setService4(!service4)}
                    >
                      Name Change Application
                    </a>
                  </li>
                  <li>
                    <a
                      class="hover:underline  sm:text-sm md:text-base"
                      onClick={() => setService8(!service8)}
                    >
                      Police Certificate (ECPIC)
                    </a>
                  </li>
                  <li>
                    <a
                      class="hover:underlin sm:text-sm md:text-base"
                      onClick={() => setService7(!service7)}
                    >
                      Police Check outside Canada
                    </a>
                  </li>
                  <li>
                    <a
                      class="hover:underline sm:text-sm md:text-base"
                      onClick={() => setService5(!service5)}
                    >
                      USA Entry Waiver
                    </a>
                  </li>
                  <li>
                    <a
                      class="hover:underline sm:text-sm md:text-base"
                      onClick={() => setService6(!service6)}
                    >
                      Record Suspension
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <div class="sm:flex sm:items-center sm:justify-between text-center m-auto">
            <p class="text-[#A4A4A4] text-center m-auto">
              Copyright © 2021{' '}
              <a
                href="https://www.1choicefingerprint.ca/"
                class="hover:underline"
              >
                1 Choice Fingerprint Services Ltd.
              </a>
              All Rights Reserved. The 1 Choice Fingerprint and 1 Choice
              Fingerprint word mark is a registered trademark of 1 Choice
              Fingerprint Services Ltd., in Canada and other countries. The "1
              Choice & 1 Choice” logo is a registered trademark of 1 Choice
              Fingerprint Services Ltd. Use of this Site is subject to express
              terms of use. By using this site, you signify that you agree to be
              bound by these Universal Terms of Service.
            </p>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default App

// flex flex-row justify-center space-x-[7rem] align-items
